import { MediaType } from '@prisma/client';
import Head from 'next/head';
import { useMemo } from 'react';
import { OgDataItemType } from '~/pages/api/extends/pages/types';
import { useSystem } from '~/store/system.store';

export function Meta<TImage extends { nsfwLevel: number; url: string; type?: MediaType }>({
  links = [],
  schema,
  deIndex,
  images,
  ogData,
  seoData,
}: {
  // title?: string;
  // description?: string;
  links?: React.LinkHTMLAttributes<HTMLLinkElement>[];
  schema?: object;
  deIndex?: boolean;
  images?: TImage | TImage[] | null;
  // imageUrl?: string;
  seoData?: SEOData;
  ogData?: { [key: string]: OgDataItemType };
}) {
  // const seo = useAppSelector((state) => state.systems.system?.seoData);
  const ogDataSystem = useSystem((state) => state.data?.ogData);
  const seoDataSystem = useSystem((state) => state.data?.seoData);
  // const _images = images ? ([] as TImage[]).concat(images) : undefined;
  // const _image = _images?.find((image) => getIsSafeBrowsingLevel(image.nsfwLevel));
  // const _imageProps =
  //   _image?.type === 'video' ? { anim: false, transcode: true, optimized: true } : {};
  // const _imageUrl = _image ? getEdgeUrl(_image.url, { width: 1200, ..._imageProps }) : imageUrl;

  const imagesOG = (value?: string) => {
    if (typeof window !== "undefined" && value) {
      return window.location.origin + value;
    } else if (typeof window !== "undefined" && ogDataSystem?.image) {
      return window.location.origin + ogDataSystem?.image;
    } else if (typeof window !== "undefined" && seoDataSystem?.image) {
      return window.location.origin + seoDataSystem?.image;
    } else {
      return '';
    }
  }

  const custom = useMemo(() => ({
    title: seoData?.title || seoDataSystem?.title || '',
    description: seoData?.description || seoDataSystem?.description,
    keywords: seoData?.keywords || seoDataSystem?.keywords,
    canonical: seoData?.canonicalUrl || typeof window !== "undefined" ? window.location.origin + window.location.pathname : '',
    noIndex: seoData?.noIndex,
    ogTitle: ogData?.facebook?.title || seoData?.title || seoDataSystem?.title,
    ogDescription: ogData?.facebook?.description || seoData?.description || seoDataSystem?.description,
    ogImage: imagesOG(ogData?.facebook?.image || ''),
    twTitle: ogData?.twitter?.title || seoData?.title || seoDataSystem?.title,
    twDescription: ogData?.twitter?.description || seoData?.title || seoDataSystem?.title,
    twImage: ogData?.twitter?.image || seoData?.image || seoDataSystem?.image,
  }), [seoData, ogData, seoDataSystem]);

  const removePrefixCanonical = (link: string) => (link.substring(link.length - 1) === '/' ? link.substring(0, link.length - 1) : link);

  return (
    <Head>
      {
        typeof window !== "undefined" && (
          <link rel="alternate" href={window.location.href} hrefLang="en-EN" />
        )
      }
      <title>{custom.title}</title>
      {custom.description && <meta name="description" content={custom.description} />}
      {custom?.keywords && <meta name="keyword" content={custom.keywords} />}
      <link rel="canonical" href={removePrefixCanonical(custom.canonical)} />
      {deIndex && <meta name="robots" content="noindex,nofollow" />}

      {/* Open Graph Meta Tags */}
      {custom.ogTitle && <meta property="og:title" content={custom.ogTitle} />}
      {custom.ogDescription && <meta property="og:description" content={custom.ogDescription} />}
      {custom.ogImage && (
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:image" content={custom.ogImage} />
          <meta name="robots" content="max-image-preview:large" />
        </>
      )}
      {
        typeof window !== "undefined" && (
          <meta property="og:url" content={window.location.href} />
        )
      }
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      {custom.twTitle && <meta name="twitter:title" content={custom.twTitle} />}
      {custom.twDescription && <meta name="twitter:description" content={custom.twDescription} />}
      {custom.twImage && <meta name="twitter:image" content={custom.twImage} />}

      {links.map((link, index) => (
        <link key={link.href || index} {...link} />
      ))}
      {schema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
          key="product-schema"
        ></script>
      )}
    </Head>
  );
}
