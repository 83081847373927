import axios from 'axios';

import {
  InfoLoginGoogleDataType,
  LoginParamsTypes,
  AuthResTypes,
  CustomerRegisterParams,
  ProfileDataType,
  UpdateProfileParams,
  SendOtpParams,
  VerifyOtpParams,
  UpdatePasswordOtpParams,
  UsageHistoriesTypes,
  LoginSocialParamsTypes,
  UpdateBillingParams,
  BillingDataType,
  CheckFocusParamsTypes,
  AllowNotifyParamsTypes,
  OAuthParamsTypes,
  LoginSocialType,
  PKCESocialDataType,
  SendOtpResponse,
  TCheckFocus
} from './types';

import axiosInstance from '../common/instance';

export const getUserInfoLoginGoogle = async (access_token: string):
  Promise<InfoLoginGoogleDataType> => {
  const res = await axios.get(
    'https://www.googleapis.com/oauth2/v3/userinfo',
    { headers: { Authorization: `Bearer ${access_token}` } },
  );
  return res.data;
};

export const loginGoogleService = async (params: OAuthParamsTypes):
  Promise<InfoLoginGoogleDataType> => {
  const res = await axios.get(
    'https://accounts.google.com/o/oauth2/v2/auth',
    { params }
  );
  return res.data;
};

export const loginService = async (params: LoginParamsTypes):
  Promise<AuthResTypes> => {
  const res = await axiosInstance.post('customers/login', params);
  return res.data;
};

export const loginSocialService = async (params: LoginSocialParamsTypes):
  Promise<AuthResTypes> => {
  const res = await axiosInstance.post('customers/login-social', params);
  return res.data.data;
};

export const logoutService = async (): Promise<void> => {
  await axiosInstance.post('customers/logout');
};

export const getProfileService = async (): Promise<ProfileDataType> => {
  const res = await axiosInstance.get('customers/profile');
  return res.data.data;
};

export const getBillingService = async (): Promise<BillingDataType> => {
  const res = await axiosInstance.get('customers/billing-address');
  return res.data.data;
};

export const postCustomerRegisterService = async (
  params: CustomerRegisterParams
): Promise<AuthResTypes> => {
  const res = await axiosInstance.post('customers/register', params);
  return res.data;
};

export const updateCustomerProfileService = async (
  params: UpdateProfileParams
): Promise<void> => {
  const res = await axiosInstance.put('customers/update-profile', params);
  return res.data.data;
};

export const updateBillingAddressService = async (
  params: UpdateBillingParams
): Promise<void> => {
  const res = await axiosInstance.post('customers/billing-address', params);
  return res.data.data;
};

export const sendOtpService = async (params: SendOtpParams): Promise<SendOtpResponse> => {
  const res = await axiosInstance.post('customers/send-otp', params);
  return res.data.data;
};

export const verifyOtpService = async (params: VerifyOtpParams): Promise<any> => {
  const res = await axiosInstance.post('customers/verify-otp', params);
  return res;
};

export const updatePasswordOtpService = async (params: UpdatePasswordOtpParams): Promise<any> => {
  const res = await axiosInstance.post('customers/update-password', params);
  return res;
};

export const getTokenSocketService = async (): Promise<string> => {
  const res = await axiosInstance.post('customers/get-token');
  return res.data.data?.token;
};

export const customerStartService = async (): Promise<void> => {
  await axiosInstance.post('customers/service-start');
};

export const customerStopService = async (): Promise<void> => {
  await axiosInstance.post('customers/service-stop');
};

export const getUserHistoryService = async (params?: RequestParamsTypes):
  Promise<APIPaginationResponse<UsageHistoriesTypes[]>> => {
  const res = await axiosInstance.get('customers/customer-usage-histories', { params });
  return res.data;
};

export const updatePasswordService = async (params?: ChangePasswordParamsTypes): Promise<void> => {
  await axiosInstance.post('customers/change-password', params);
};

export const postAllowNotifyService = async (params: AllowNotifyParamsTypes): Promise<void> => {
  await axiosInstance.post('customers/allow-notify', params);
};

export const getPKCESocialDataService = async (
  provider: LoginSocialType
): Promise<PKCESocialDataType> => {
  const response = await axiosInstance.post('customers/pkce-social', { provider });
  return response.data.data;
};

export const postCheckFocusService = async (params?: CheckFocusParamsTypes):
  Promise<TCheckFocus> => {
  const res = await axiosInstance.post('customers/check-focus', params);
  return res.data;
};