import { useEffect } from 'react';
import { getAccessToken } from '~/pages/api/extends/common/storage';
import { useAuth } from '~/store/auth.store';
import { useSystem } from '~/store/system.store';
import useCheckOtherTab from './useCheckOtherTab';

type Props = {
  children: React.ReactNode;
};

const Initialize: React.FC<Props> = ({ children }) => {
  const { getGeneral, getHeaderFooter, getStaticPageAll } = useSystem((state) => state);
  const { getProfile } = useAuth((state) => state);

  useEffect(() => {
    getGeneral();
    getHeaderFooter();
    getStaticPageAll();

    const token = getAccessToken();
    if (token) {
      getProfile();
    }
  }, []);

  useCheckOtherTab();

  return <>{children}</>;
};

export default Initialize;
