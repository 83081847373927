/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from 'react';

import { getProfileService, postCheckFocusService } from '~/pages/api/extends/auth';
import { CheckFocusParamsTypes, ProcessingType } from '~/pages/api/extends/auth/types';
import { getAccessToken } from '~/pages/api/extends/common/storage';
import { useAuth } from '~/store/auth.store';

const useCheckOtherTab = () => {
  const { setProfile, profile } = useAuth((state) => state);
  const usingServer = profile?.customerData.usingServer;
  const email = profile?.customerData.email;
  const phone = profile?.customerData.phone;
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const isHiddenRef = useRef<boolean>(false);

  useEffect(() => {
    const checkFocusService = async (data: CheckFocusParamsTypes) => {
      try {
        await postCheckFocusService(data);
      } catch {
        // Empty
      }
    };

    const ping = () => {
      checkFocusService({
        isFocus: true
      });
      intervalRef.current = setInterval(() => {
        checkFocusService({
          isFocus: true
        });
      }, 1000 * 60);
    };

    const clearPing = () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };

    const handleCheckFocus = (statusUsing?: ProcessingType) => {
      if (statusUsing === 'starting' && !isHiddenRef.current) {
        clearPing();
        ping();
      } else {
        clearPing();
      }
    };

    handleCheckFocus(usingServer);

    const handleVisibilityChange = async () => {
      const token = getAccessToken();
      if (document.hidden) {
        isHiddenRef.current = true;
        clearPing();
        if (token) {
          checkFocusService({
            isFocus: false
          });
        }
      } else {
        isHiddenRef.current = false;
        if (token) {
          try {
            const data = await getProfileService();
            setProfile(data);
            handleCheckFocus(data.customerData.usingServer);
          } catch {
            // Empty
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearPing();
    };
  }, [email, phone, usingServer]);
};

export default useCheckOtherTab;
