import { ActionIcon, Group, Stack, UnstyledButton } from '@mantine/core';
import { CosmeticType } from '@prisma/client';
import { IconBrush, IconInfoCircle } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { useCardStyles } from '~/components/Cards/Cards.styles';
import { FeedCard } from '~/components/Cards/FeedCard';
import { RoutedDialogLink } from '~/components/Dialog/RoutedDialogProvider';
import { EdgeMedia } from '~/components/EdgeMedia/EdgeMedia';
import { getSkipValue, shouldAnimateByDefault } from '~/components/EdgeMedia/EdgeMedia.util';
import { ImageContextMenu } from '~/components/Image/ContextMenu/ImageContextMenu';
import { OnsiteIndicator } from '~/components/Image/Indicators/OnsiteIndicator';
import { ImageMetaPopover2 } from '~/components/Image/Meta/ImageMetaPopover';
import { useImagesContext } from '~/components/Image/Providers/ImagesProvider';
import { ImageGuard2 } from '~/components/ImageGuard/ImageGuard2';
import { MediaHash } from '~/components/ImageHash/ImageHash';
import { Reactions } from '~/components/Reaction/Reactions';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { DEFAULT_EDGE_IMAGE_WIDTH } from '~/server/common/constants';
import { ImagesInfiniteModel } from '~/server/services/image.service';
import { redirectToWorkspaceWithPrompt } from '~/utils/functions';
import { trpc } from '~/utils/trpc';
import HoverActionButton from './components/HoverActionButton';

function UnroutedImageCard({ data }: Props) {
  const { classes: sharedClasses, cx } = useCardStyles({
    aspectRatio: data.width && data.height ? data.width / data.height : 1,
  });
  const router = useRouter();
  const features = useFeatureFlags();
  const currentUser = useCurrentUser();

  const { data: dataGenerate } = trpc.image.getGenerationData.useQuery({ id: data.id });
  const imageCurrent = dataGenerate?.resources?.find((item) => item.id === data.id);
  const { meta } = dataGenerate ?? {};

  const cardDecoration = data.user.cosmetics?.find(
    ({ cosmetic }) => cosmetic.type === CosmeticType.ContentDecoration
  ) as (typeof data.user.cosmetics)[number] & {
    data?: { lights?: number; upgradedLights?: number };
  };

  const originalAspectRatio = data.width && data.height ? data.width / data.height : 1;
  const onSite = data.onSite;
  const imageWidth =
    originalAspectRatio > 1
      ? DEFAULT_EDGE_IMAGE_WIDTH * originalAspectRatio
      : DEFAULT_EDGE_IMAGE_WIDTH;

  const shouldAnimate = shouldAnimateByDefault({
    ...data,
    forceDisabled: !currentUser?.autoplayGifs,
  });

  return (
    <FeedCard className={sharedClasses.link} frameDecoration={data.cosmetic}>
      <div className={sharedClasses.root}>
        <ImageGuard2 image={data}>
          {(safe) => (
            <>
              {onSite && <OnsiteIndicator />}
              <Group
                position="apart"
                align="start"
                spacing={4}
                className="absolute inset-x-2 top-2 z-10"
                style={{ pointerEvents: 'none' }}
              >
                <ImageGuard2.BlurToggle radius="xl" h={26} sx={{ pointerEvents: 'auto' }} />
                {safe && (
                  <Stack spacing="xs" ml="auto" style={{ pointerEvents: 'auto' }}>
                    <ImageContextMenu image={data} />
                    {features.imageGeneration && data.hasMeta && (
                      <HoverActionButton
                        label="Remix"
                        size={30}
                        color="white"
                        variant="filled"
                        data-activity="remix:image-card"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          redirectToWorkspaceWithPrompt(meta, imageCurrent?.modelId, data?.modelVersionId);
                          // generationPanel.open({
                          //   type: 'image',
                          //   id: data.id,
                          // });
                        }}
                      >
                        <IconBrush stroke={2.5} size={16} />
                      </HoverActionButton>
                    )}
                  </Stack>
                )}
              </Group>
              {safe ? (
                <div
                  className={data.cosmetic ? sharedClasses.frameAdjustment : undefined}
                  style={{ height: '100%' }}
                >
                  <EdgeMedia
                    src={data.url}
                    name={data.name ?? data.id.toString()}
                    alt={data.name ?? undefined}
                    type={data.type}
                    width={imageWidth}
                    className={sharedClasses.image}
                    wrapperProps={{ style: { height: '100%', width: '100%' } }}
                    anim={shouldAnimate}
                    skip={getSkipValue(data)}
                    loading="lazy"
                    contain
                  />
                </div>
              ) : (
                <MediaHash {...data} />
              )}
            </>
          )}
        </ImageGuard2>

        <Stack
          className={cx('footer', sharedClasses.contentOverlay, sharedClasses.bottom)}
          spacing="sm"
        >
          {data.user.id !== -1 && (
            <UnstyledButton
              sx={{ color: 'white', alignSelf: 'flex-start' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                router.push(`/user/${data.user.username}`);
              }}
            >
              {/* <UserAvatar
                // Explicit casting to comply with ts
                user={data.user as ImagesInfiniteModel['user']}
                avatarProps={{ radius: 'xl', size: 32 }}
                withUsername
              /> */}
            </UnstyledButton>
          )}
          <Group spacing={4} position="apart">
            <Reactions
              className={sharedClasses.reactions}
              entityId={data.id}
              entityType="image"
              reactions={data.reactions}
              metrics={{
                likeCount: data.stats?.likeCountAllTime,
                dislikeCount: data.stats?.dislikeCountAllTime,
                heartCount: data.stats?.heartCountAllTime,
                laughCount: data.stats?.laughCountAllTime,
                cryCount: data.stats?.cryCountAllTime,
                tippedAmountCount: data.stats?.tippedAmountCountAllTime,
              }}
              targetUserId={data.user.id}
            />
            {data.hasMeta && (
              <ImageMetaPopover2 imageId={data.id} modelVersionId={data?.modelVersionId}>
                <ActionIcon className={sharedClasses.infoChip} variant="light">
                  <IconInfoCircle color="white" strokeWidth={2.5} size={18} />
                </ActionIcon>
              </ImageMetaPopover2>
            )}
          </Group>
        </Stack>
      </div>
    </FeedCard>
  );
}
export function ImageCard({ data }: Props) {
  const context = useImagesContext();

  return (
    <RoutedDialogLink name="imageDetail" state={{ imageId: data.id, ...context }}>
      <UnroutedImageCard data={data} />
    </RoutedDialogLink>
  );
}

type Props = { data: ImagesInfiniteModel };
