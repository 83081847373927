import { HomePageData, ModelsPageData, PrivacyPolicyData, StaticAllData } from './types';

import axiosInstance from '../common/instance';

export const getHomePageService = async (): Promise<HomePageData> => {
  const res = await axiosInstance.get('pages/static/home-page');
  return res.data.data;
};

export const getPageService = async (slug: string): Promise<HomePageData> => {
  const res = await axiosInstance.get(`pages/${slug}`);
  return res.data.data;
};

export const getModelPageService = async (slug: string): Promise<ModelsPageData> => {
  const res = await axiosInstance.get(`pages/${slug}`);
  return res.data.data;
};

export const getPagePrivacyPolicyService = async (slug: string): Promise<PrivacyPolicyData> => {
  const res = await axiosInstance.get(`pages/${slug}`);
  return res.data.data;
};

export const getPageStaticAllService = async (): Promise<StaticAllData[]> => {
  const res = await axiosInstance.get('pages/static/all');
  return res.data.data;
};
